import { camelCase } from 'lodash-es'

export const strapiGetRouteReference = (uid: string) => {
  let uidString = uid?.includes('.') ? uid?.split('.')?.pop() : uid
  uidString = camelCase(uidString)
  switch (uidString) {
    case 'aboutPage':
      return {
        name: 'about',
        slug: false
      }

    case 'homePage':
      return {
        name: 'index',
        slug: false
      }

    case 'pageEntry':
      return {
        name: 'slug',
        slug: true
      }

    case 'participantsPage':
    case 'fellowsPage':
      return {
        name: 'fellows',
        slug: false
      }

    case 'participantEntry':
      return {
        name: 'fellows-slug',
        slug: true
      }

    case 'permanentWorksPage':
      return {
        name: 'permanent-works',
        slug: false
      }

    case 'permanentWorkEntry':
      return {
        name: 'permanent-works-slug',
        slug: true
      }

    case 'programsPage':
    case 'programPage':
      return {
        name: 'program',
        slug: false
      }

    case 'programEntry':
      return {
        name: 'program-slug',
        slug: true
      }

    case 'publicationsPage':
      return {
        name: 'publications',
        slug: false
      }

    case 'publicationEntry':
      return {
        name: 'publications-slug',
        slug: true
      }

    default:
      return {
        name: 'index',
        slug: false
      }
  }
}
